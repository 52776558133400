@use 'src/styles/theme';
@use 'src/styles/utils';

.description {
  @include utils.with-ellipsis(2);
  color: inherit;

  @include theme.breakpointDown('md') {
    @include utils.with-ellipsis(3);
  }
}
