@use 'src/styles/theme';

.root {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.innerContainer {
  position: relative;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slot {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  &__center {
    margin: 0 auto;
  }
}
