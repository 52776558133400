@use 'src/styles/theme';

.root {
  @include theme.breakpointDown('md') {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    overflow-x: scroll;
    padding-left: theme.spacing(2);
    position: relative;
    right: 50%;
    scroll-snap-type: x mandatory;
    scroll-padding: 20px;
    width: 100vw;
  }

  .cardWrapper {
    padding-right: 20px;
  }
}
