@use 'src/styles/theme';
@use 'src/styles/utils';

.thumbnail {
  @include theme.create-margin(0, 1.5, 0, 0);
  width: 45px;
  flex-shrink: 0;

  &__locked {
    width: unset;
  }

  &__search {
    border-radius: 8px;
  }
}

.titleContainer {
  margin-right: 0;
}

.title {
  @include utils.with-ellipsis(1);
  line-height: 1;

  &__search {
    width: 100%;
    color: theme.$black;
  }
}

.subtitle {
  @include utils.with-ellipsis(1);

  &__search {
    color: theme.$black;
  }
}

.thumbnailRatio {
  flex-basis: 40px;
  flex-shrink: 0;
  width: 40px;
}

.thumbnailSkeletonContainer {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.thumbnailSkeleton {
  border-radius: 6px;
}
