@use 'src/styles/constants';
/* This project uses PostCss Normalize for adding a CSS Reset. https://create-react-app.dev/docs/adding-css-reset/ */
@import-normalize;

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(constants.$background-color-variable, white);
}
