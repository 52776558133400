@use 'src/styles/theme';

.root {
  color: theme.$l4;
  margin-bottom: 0;
  margin-top: 0;

  &__level1 {
    font-family: theme.$primary-heading-font;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.42;

    @include theme.breakpointDown(md) {
      font-size: 20px;
      line-height: 1.3;
    }
  }

  &__level2 {
    font-family: theme.$secondary-heading-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__level3 {
    font-family: theme.$primary-heading-font;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.44;
  }

  &__level4 {
    font-family: theme.$primary-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__level5 {
    font-family: theme.$primary-heading-font;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
  }
}
