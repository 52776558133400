@use 'src/styles/theme';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.text {
  color: theme.$white;
}
