@use 'src/styles/theme';

.root {
  background-color: theme.$s1;
  border-radius: 8px;
  color: theme.$white;
  display: flex;
  padding: theme.$spacing;
  position: absolute;
  z-index: theme.$popper-z-index;
}
