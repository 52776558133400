@use 'src/styles/theme';

.root {
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
}

.contents {
  @include theme.create-padding(7, 2, 0, 2);
  display: flex;
  align-items: center;
  height: theme.$header-height-desktop;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include theme.breakpointDown('md') {
    flex-direction: column;
    // there is a design requirement that if there is only 1 line of text, the
    // text block within the header should shift to the bottom of the header rather
    // than shifting up or the header getting shorter.  to accomplish this, the header
    // has a fixed height on mobile
    height: theme.$header-height-mobile;
  }

  // fade overlay on top of background image
  &::before {
    background-image: linear-gradient(
      to bottom,
      rgba(theme.$dark3-main, 0.5),
      theme.$d2
    );
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

// fade overlay beneath header to fade out contents as it scrolls beneath the header
.fader {
  height: theme.$header-fader-height;
  background-image: linear-gradient(to bottom, theme.$d2, rgba(theme.$d2, 0%));
  pointer-events: none;
}

.background {
  background-color: theme.$d2;
}

.contentsWrapper {
  flex-grow: 1;
  width: 100%;
  padding-top: 38px;

  @include theme.breakpointDown('md') {
    padding-top: 20px;
  }
}
