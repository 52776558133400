@use 'src/styles/theme';

@mixin themify-button($bg-color, $fg-color, $selector) {
  #{$selector} {
    background-color: $bg-color;
    color: $fg-color;
  }

  .root__outlined#{$selector},
  .root__transparent#{$selector} {
    background-color: transparent;
  }

  .root__contained#{$selector} {
    border-color: $fg-color;
  }
}

.root {
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  opacity: 1;
  padding: 0;
  position: relative;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &:not(:active) {
    @include theme.transition(opacity);
  }

  &:hover:not([disabled]) {
    opacity: 0.5;
  }

  &:active:not([disabled]) {
    opacity: 0.8;
  }

  &__contained,
  &__outlined {
    border-style: solid;

    &.root__medium {
      border-width: 1.5px;
    }

    &.root__large {
      border-width: 2px;
    }

    &.root__x-large {
      border-width: 2.3px;
    }
  }

  &__medium {
    font-size: 1em;
    height: 35px;
    width: 35px;
  }

  &__large {
    font-size: 1.5em;
    height: 50px;
    width: 50px;
  }

  &__x-large {
    font-size: 1.8em;
    height: 60px;
    width: 60px;
  }
}

@include themify-button(rgba(theme.$d1, 0.75), theme.$white, '.root__dark');
@include themify-button(
  theme.$action-primary-main,
  theme.$white,
  '.root__primary'
);
