@use 'sass:color';
@use 'src/styles/theme';

.root {
  padding-bottom: theme.spacing(8);
}

.description {
  // NB: this should match styles in Text.module.scss (or at least some style in
  // the Typography module).  !important needs to be added to all rules in case
  // html from rss feed contains inline styles.  There doesn't seem to be any easy
  // way to extend styles from Typography while also adding !important to each one,
  // so the styles are repeated here.
  &,
  * {
    color: theme.$l4 !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 1.5 !important ;
    letter-spacing: 0.5px !important;
  }

  a,
  span {
    @include theme.transition(color);
    color: theme.$action-primary-main !important ;
    font-weight: bold !important;
    text-decoration: none !important;

    &:hover {
      color: color.scale(
        theme.$action-primary-main,
        $lightness: -25%
      ) !important;
    }
  }

  span {
    &:hover {
      cursor: pointer;
    }
  }
}

.divider {
  @include theme.create-margin(5, 0);
}

.cta {
  margin: theme.spacing(5) auto;
}

.link {
  &,
  &:hover,
  &:active {
    text-decoration: none;
  }
}
