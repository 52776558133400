@use 'src/styles/theme';
@import 'src/styles/utils.scss';

.root {
  flex-grow: 1;
  min-width: 0;
}

.episode {
  @include with-ellipsis(1);
  color: theme.$l1;
  line-height: 1;
  &__link:hover {
    text-decoration: underline;
  }
}

.link {
  text-decoration: none;
}

.podcast {
  @include with-ellipsis(1);
  letter-spacing: normal;
  text-transform: none;
  &__link:hover {
    text-decoration: underline;
  }
}

.details {
  color: theme.$action-tertiary-main;
}

.thumbnail {
  width: 40px;
  flex-shrink: 0;
}
