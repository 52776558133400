@use 'src/styles/theme';

.root {
  height: theme.$slider-thickness;
  position: relative;
  width: 100%;

  &__disabled {
    pointer-events: none;
  }
}

.rail {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  // the progress bar is only 4px tall.  this rule makes the mouse target larger by
  // adding 20px of height in the form of vertical padding
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    padding: theme.$slider-hover-padding 0;
    position: absolute;
    z-index: 1;
  }
}

.thumb {
  background-color: transparent;
  border-radius: 50%;
  height: 100%;
  left: -2.5px;
  position: absolute;
  top: 0;
  width: 5px;
  z-index: 1;
}
