@use 'src/styles/theme';

.root {
  align-items: center;
  color: inherit;
  display: flex;
}

.icon {
  @include theme.create-margin(0, 1, 0, 0);
}

.action {
  margin-left: auto;
}

.contentRow {
  text-align: left;
  width: 100%;
}

.subtitle {
  color: inherit;
  margin-top: theme.spacing(1);
}

.title {
  color: inherit;
  margin-right: theme.spacing(2);
}
