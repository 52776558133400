@use 'src/styles/theme';
@import 'src/styles/utils';

.root {
  align-items: center;
  display: flex;
  position: relative;

  margin-bottom: 16px;
  margin-top: 8px;

  @include theme.breakpointDown('md') {
    margin-bottom: 10px;
    margin-top: 4px;
  }
}

.subtitle {
  @include with-ellipsis();
  color: theme.$white;
  @include theme.breakpointDown('md') {
    @include with-ellipsis(2);
  }
}
