@use 'src/styles/utils';

$header-height: 35px;

.header {
  height: $header-height;
}

.playButton {
  &,
  &__skeleton {
    box-shadow: 0 1px 17px 0 rgba(#000, 0.5);
    flex-basis: $header-height;
    flex-shrink: 0;
  }

  &__skeleton {
    box-shadow: none;
    height: $header-height;
    margin-left: auto;
  }
}

.thumbnail {
  border-radius: 1.5px;
  flex-shrink: 0;
  height: $header-height;
  width: $header-height;
}

.title {
  @include utils.with-ellipsis(2);
}
