@use 'src/styles/theme';

.root {
  color: theme.$l4;

  &__label {
    font-family: theme.$primary-font;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;

    @include theme.breakpointDown('md') {
      font-size: 10px;
    }
  }

  &__label2 {
    font-family: theme.$primary-font;
    color: theme.$white;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
  }

  &__details {
    font-family: theme.$primary-font;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 2px;
    line-height: 1;
    text-transform: uppercase;
  }

  &__mono {
    font-family: theme.$mono-font;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  }

  &__paragraph {
    font-family: theme.$primary-font;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
}
