@use 'src/styles/theme';

.root {
  padding-top: theme.$header-height-desktop;

  @include theme.breakpointDown('md') {
    padding-top: theme.$header-height-mobile;
  }
}

.contents {
  @include theme.create-margin(2.8, 0);

  margin-bottom: theme.$episode-contents-bottom-spacing;
  min-height: calc(
    100vh -
      (
        theme.$episode-contents-bottom-spacing + theme.$header-height-condensed +
          theme.$header-fader-height
      )
  );
  width: 100%;
}
