@use 'src/styles/theme';

.root {
  display: grid;
  gap: 16px 16px;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: visible !important;

  @include theme.breakpointDown('md') {
    gap: 8px 8px;
  }
}
