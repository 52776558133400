.aspectRatio {
  position: relative;

  &__width {
    padding-top: var(--aspect-ratio);
  }

  &__height {
    height: 100%;
  }
}

.svg {
  height: 100%;
  width: auto;
}

.child {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
