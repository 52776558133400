@use 'src/styles/theme';
@import 'src/styles/utils';

.root {
  width: 100%;

  @include theme.breakpointDown('md') {
    margin-top: auto;
    justify-content: space-between;
    width: 100vw;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
