.dialogCloseButton {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 3;
}

.swiper {
  height: 100vh;
}

.fade {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0) 98%
  );
  height: 70px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
}
