@use 'src/styles/theme';
@use 'sass:color';

.root {
  padding: inherit;
}

.icon {
  @include theme.transition(color);
  color: theme.$white;
  height: 30px;
  width: auto;

  &:hover {
    // matches design #c6cad4
    color: color.scale(theme.$l4, $lightness: -10%, $saturation: -50%);
  }
}
