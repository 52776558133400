@use 'src/styles/theme';

.controls__secondary {
  @include theme.breakpointDown('md') {
    display: none;
  }
}

.playButton {
  z-index: inherit;
}
