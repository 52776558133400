@use 'src/styles/theme';
@use 'src/styles/utils';

.root {
  display: block;
  width: 1000px;

  @include theme.breakpointDown('md') {
    min-width: 335px;
    width: 100%;
  }
}

.card {
  @include theme.transition(color, 300ms, ease-in-out);
  color: theme.$l4;
  border-radius: 24px;

  .root:hover & {
    color: theme.$white;
  }
}

.backgroundImage {
  &:before {
    opacity: 0.9;
  }
}
