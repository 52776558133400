@mixin set-width($width) {
  flex-basis: $width;
  flex-shrink: 0;
  width: $width;
}

.root {
  overflow: hidden;
  position: relative;

  &__small {
    border-radius: 2px;
    @include set-width(40px);
  }

  &__large {
    border-radius: 10px;
    @include set-width(150px);
  }
}

.ratio {
  background-color: black;
}

.image {
  object-fit: contain;
}

.skeletonContainer {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.skeleton {
  border-radius: 0;
}
