.root {
  overflow: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.image {
  filter: blur(var(--blur-value));
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
