@use 'src/styles/theme';

.container {
  width: 88px;
  height: 88px;

  border-radius: 50%;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  &__small {
    width: 32px;
    height: 32px;
  }
}

.icon {
  color: theme.$common-white;
  width: 28px;
  height: 32px;
  &__small {
    width: 12.7px;
    height: 14.5px;
  }
}
