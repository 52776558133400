@use 'sass:color';
@use 'src/styles/theme';

.anchor {
  @include theme.transition(color);
  color: theme.$action-primary-main;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: theme.$action-primary-dark;
  }
}
