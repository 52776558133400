@use 'src/styles/theme';

$top-position: 24px;
$right-position: 24px;
$bottom-position: 24px;
$border-radius: 24px;

.root {
  position: fixed;
  top: #{$top-position};
  right: #{$right-position};
  bottom: #{$bottom-position};
  background-color: theme.$d2;
  border-radius: $border-radius;
  box-shadow: 0 2px 30px 2px rgba(0, 0, 0, 0.15);
  border: solid 2px theme.$d4;
  width: 50%;

  @include theme.breakpointDown('md') {
    width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
}

.header {
  height: theme.$queue-header-height;
  border-bottom: 2px solid theme.$d4;
  border-radius: 24px 24px 0 0;
}

.container {
  overflow-y: auto;
  height: calc(100% - theme.$queue-header-height);
  border: none;
  padding: 24px;
  width: 100%;

  :global {
    .simplebar-wrapper,
    .simplebar-mask {
      border-radius: $border-radius;
    }

    .simplebar-track.simplebar-vertical {
      top: 10px;
      bottom: 10px;
    }
  }
}
