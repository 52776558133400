@use 'src/styles/constants';
@use 'src/styles/theme';

$background-color: theme.$d2;

.root {
  @include theme.create-padding(0, 2);
  background-color: $background-color;
  min-height: 100vh;

  &__fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

:root {
  // @sparemin/blockhead Dialog component will prevent the page beneath the modal
  // from scrolling.  as part of this implementation, the dialog will hide the page's
  // scrollbars.  If the background of the body element isn't set, then a white bar
  // will show where the scrollbars should be after the Dialog component removes them.
  //
  // see reset.scss body rule
  #{constants.$background-color-variable}: $background-color;
}
