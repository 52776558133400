@use 'src/styles/theme';

.root {
  // overlay on top of the background to make the text more visible
  &:before {
    background-color: theme.$dark3-main;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
