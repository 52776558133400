@use 'src/styles/theme';

.swiper {
  height: 100vh;
  width: 100vw;
}

.swiperSlide {
  display: flex;
  justify-content: center;
}

.clip {
  display: flex;
  justify-content: center;
}
