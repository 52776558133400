@use 'src/styles/theme';

.contents {
  &__hasParts {
    margin-bottom: calc(
      100vh - theme.$header-height-condensed - theme.$header-fader-height -
        theme.$episode-part-condensed-min-height
    );
    min-height: unset;
  }
}
