@use 'src/styles/theme';

.root {
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

  @include theme.breakpointDown('md') {
    flex-grow: 1;
    width: 100%;
  }
}
