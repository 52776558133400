@use 'src/styles/theme';

.playContainer {
  align-items: center;
  background-color: theme.$white;
  border-radius: 100%;
  display: flex;
  height: 14px;
  justify-content: center;

  width: 14px;
  color: transparent;
}

.playIcon {
  color: theme.$action-primary-main;
  height: auto;
  margin-left: 1px;
  width: 5px;
}

.duration {
  color: theme.$action-tertiary-main;
  font-weight: bold;
}
