@use 'src/styles/theme';

.root {
  align-items: center;
  background-color: theme.$d2;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
}

.logo {
  color: theme.$l4;
  height: 30px;
}

.content {
  margin-bottom: 50px;
}

.link {
  &,
  &:hover {
    text-decoration: none;
  }
}
