@use 'src/styles/theme';

.rail {
  @include theme.transition(background-color);
  align-items: center;
  background-color: rgba(theme.$l4, 0.25);
  display: flex;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  margin-right: theme.$slider-chapter-break-size;
  position: relative;
  width: calc(var(--width) - theme.$slider-chapter-break-size);

  &:hover {
    background-color: theme.$l4;
  }

  &:after {
    content: '';
    height: 100%;
    padding: theme.$slider-hover-padding 0;
    position: absolute;
    width: calc(100% + theme.$slider-chapter-break-size);
    z-index: 2;
  }

  &:last-of-type {
    margin-right: 0;
    width: var(--width);
  }
}

.track {
  @include theme.transition(background-color);
  background-color: rgba(theme.$action-primary-main, 0.75);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: var(--width);

  .rail:hover & {
    background-color: theme.$action-primary-main;
  }
}
