@use 'src/styles/theme';

$max-overlay-visible: 86px;
$short-screen: 750px;
$standard-screen: 860px;

.dialog {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  min-height: 95%;

  @media (min-height: #{$standard-screen}) {
    min-height: unset;
    height: calc(100% - #{$max-overlay-visible});
  }
}

.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  min-height: inherit;
  height: inherit;
}

.backdrop {
  backdrop-filter: blur(30px);
}

.card {
  display: flex;
  bottom: 0;
  flex-direction: column;
  border-radius: 40px 40px 0 0;
  flex-grow: 1;
  padding: 40px;
}

.contents {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.artwork {
  max-width: 300px;
  @media (max-height: #{$short-screen}) {
    max-width: 250px;
  }
}

.fullwidth {
  width: 100%;
}

.handle {
  width: 16px;
  height: 16px;
  top: -6px;
  background-color: theme.$action-primary-main;
}
