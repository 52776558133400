@use 'src/styles/theme';

.root {
  @include theme.create-padding(2, 0);
  cursor: pointer;
  position: relative;
  width: 100%;
  z-index: 1;

  &:before {
    background-color: #242a34;
    content: '';
    height: 100%;
    margin-left: calc(50% - 50vw);
    max-width: 100vw;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    // TODO export times for consistency with JS code
    transition: opacity 150ms ease 150ms;
    width: 100vw;
    z-index: -1;
  }

  &__expanded {
    cursor: default;

    &:before {
      opacity: 1;
      transition: opacity 600ms ease 300ms;
    }
  }

  &:focus {
    outline: none;
  }
}

.labelContainer {
  align-items: center;
  display: flex;
  width: 100%;
}

.label {
  color: theme.$action-tertiary-main;
}

.playButton {
  color: theme.$white;
}

.suggestionsLabel {
  @include theme.create-margin(2.2, 0, 1, 0);
}

.suggestionsContainer {
  overflow: hidden;

  // TODO can any of these rules apply to non-mobile?
  @include theme.breakpointDown('md') {
    width: 100%;

    // TODO this overflow won't work because you'll see suggestions as they transition out
    overflow: visible;
    position: relative;
  }
}
