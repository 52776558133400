@use 'src/styles/constants';
@use 'src/styles/theme';

.root {
  height: calc(100vh - 200px);
}

.player {
  border: 2px solid theme.$d4;
  border-radius: inherit;
  height: 100%;
  overflow: hidden;
}

.video {
  // position video in center in case it's not the correct aspect ratio. this
  // will create a blurred background pillar/letter box
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.inner {
  border-radius: 22px;

  // without overflow and isolation, content bleeds outside of the rounded corners
  // on safari.  overflow alone doesn't work - isolation is needed to create a new
  // stacking context so that the overflow actually gets clipped
  overflow: hidden;
  isolation: isolate;
}
