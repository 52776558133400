@use 'src/styles/theme';

.root {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 200px;
  border-radius: 16px;
  padding: 24px 30px;
  box-shadow: 0 2px 30px 2px rgba(0, 0, 0, 0.15);
  outline: solid 2px #19b5fe;
  background-color: inherit;
}

.title {
  margin-bottom: 4px;
}

.subtitle {
  margin-bottom: 16px;
}

.dismiss {
  margin: 24px 0;
}
