@use 'src/styles/theme';

.root {
  padding: 20px;
  height: 50px;
  border-radius: 25px;
  z-index: 1;

  &:hover {
    background-color: rgba(255, 255, 255, 0.35);
    cursor: text;
  }

  &__label {
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: normal;
    text-transform: none;
    font-size: 14px;
    line-height: 15px;
  }

  @include theme.breakpointDown('md') {
    height: 30px;
    border-radius: 15px;
    padding: 9px 12px 9px 10px;

    &__label {
      font-family: Roboto;
      font-weight: normal;
      letter-spacing: normal;
      text-transform: none;
      font-size: 12px;
      line-height: 12px;
    }

    &__icon {
      height: 12px;
      width: 12px;
    }
  }

  @include theme.breakpointDown('xs') {
    width: 30px;

    &__label {
      display: none;
    }

    &__icon {
      height: 12px;
      width: 12px;
      margin-left: 8px;
    }
  }
}
