// breakpoints
$xs: 330px;
$md: 1324px;

// slider
$slider-thickness: 4px;
$slider-hover-padding: 10px;
$slider-chapter-break-size: 4px;

// header
$header-height-condensed: 82px;
$header-height-desktop: 308px;
$header-height-mobile: 440px;
$header-fader-height: 20px;
$header-top-desktop-condensed: 26px;
$header-top-desktop: 70px;
$header-top-mobile-condensed: 0;
$header-top-mobile: 70px;

// homepage header
$homepage-header-height: 80vh;
$homepage-footer-height: 20vh;

// episode page contents
$episode-contents-bottom-spacing: 150px;
$episode-part-condensed-min-height: 82px;

// modal
$modal-z-index: 1000;

// popper
$popper-z-index: 5;

// FollowPodcastPopper
$offset: 24;
$follow-popper-z-index: 3;

// player
$player-height-desktop: 84px;
$player-height-mobile: 64px;
$player-position-type: fixed;

// queue
$queue-header-height: 82px;

:export {
  followPodcastPopperOffset: $offset;
  headerHeightCondensed: $header-height-condensed;
  headerHeightDesktop: $header-height-desktop;
  headerHeightMobile: $header-height-mobile;
  headerTopDesktopCondensed: $header-top-desktop-condensed;
  headerTopDesktop: $header-top-desktop;
  headerTopMobileCondensed: $header-top-mobile-condensed;
  headerTopMobile: $header-top-mobile;
  homepageHeaderHeight: $homepage-header-height;
  md: $md;
  playerHeightDesktop: $player-height-desktop;
  playerHeightMobile: $player-height-mobile;
  playerPositionType: $player-position-type;
  xs: $xs;
  queueHeaderHeight: $queue-header-height;
}
