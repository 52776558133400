@use 'src/styles/utils';
@use 'src/styles/theme';

$card-width: 320px;
$card-height: 170px;

.suggestion {
  flex-shrink: 0;
  height: $card-height;
  width: $card-width;

  @include theme.breakpointDown('md') {
    scroll-snap-align: start;
  }
}

.description {
  @include utils.with-ellipsis(4);
  color: inherit;
}
