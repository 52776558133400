@use 'src/styles/theme';

.root {
  text-decoration: none;
}

.card {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  z-index: 1;

  &__disabled {
    cursor: default;
  }

  &__search {
    border-radius: 0;
  }

  // border radius implemented as ::after pseudoelement so that it sits on top
  // of the element.  padding in design was adjusted to account for border,
  // e.g. 20px padding - 2px border = 18px padding.  this achieves the same
  // effect without the math
  &:not(.card__search):after {
    @include theme.transition(border, 300ms, ease-in-out);
    border: 2px solid theme.$neutral1-dark;
    border-radius: inherit;
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover:not(.card__disabled):after {
    border-color: theme.$d5;
  }

  &:focus {
    outline: none;
  }
}
