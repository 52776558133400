@use 'src/styles/theme';
@import 'src/styles/utils';

.root {
  font-size: 16px;
  line-height: 21px;
  @include with-ellipsis(2);
}

.link,
.link:visited {
  color: inherit;
}
