@use 'src/styles/theme';

.container {
  bottom: 0;
  left: 0;
  position: theme.$player-position-type;
  width: 100vw;
  z-index: 2;

  &::before {
    $fader-height: 50px;

    background-image: linear-gradient(to top, theme.$d2, rgba(theme.$d2, 0%));
    content: '';
    height: $fader-height;
    pointer-events: none;
    position: absolute;
    // fader is 50px tall but has to fill under the 4px progress bar
    top: theme.$slider-thickness - $fader-height;
    width: 100%;
  }
}
