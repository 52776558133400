@use 'src/styles/theme';

.root {
  $calculated-margin-value: calc(var(--space-multiplier, 0) * theme.$spacing);

  &__horizontal:not(:first-child) {
    margin-left: var(--space-value, $calculated-margin-value);
  }

  &__vertical:not(:first-child) {
    margin-top: var(--space-value, $calculated-margin-value);
  }
}
