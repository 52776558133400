@use 'src/styles/theme';

.root {
  height: 100%;
}

.playerContainer {
  background-color: theme.$black;
  border-radius: 24px;
  height: 100%;
  position: relative;
}
