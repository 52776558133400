.root {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.tooltip {
  width: max-content;
}

.next {
  transform: rotate(180deg);
}
