@use './_constants.scss';

$breakpoints: (
  xs: constants.$xs,
  md: constants.$md,
);

// todo kebab case this
@mixin breakpointDown($breakpointName) {
  @media only screen and (max-width: map-get($breakpoints, $breakpointName)) {
    @content;
  }
}

@mixin breakpointUp($breakpointName) {
  @media only screen and (min-width: (map-get($breakpoints, $breakpointName) + 1)) {
    @content;
  }
}
