@use 'src/styles/theme';

.icon {
  width: 19px;
  height: 16px;
  color: theme.$white;
  @include theme.breakpointDown('md') {
    width: 14px;
    height: 12px;
  }
}

.text {
  display: flex;
  color: theme.$white;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;
}
