@use 'src/styles/theme';

.root {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

  @include theme.breakpointDown('md') {
    width: 100%;
  }
}
