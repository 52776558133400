@use 'src/styles/theme';

.buttonBox {
  // The container inherits an 8px left-margin from its parent,
  // so 24px is added here to achieve a total left-margin of 32px, which ensures appropriate
  // spacing between the episode or podcast title and the user buttons.
  margin-left: 24px;
  @include theme.breakpointDown('md') {
    width: 100%;
    margin-left: 0px;
    align-items: flex-end;
  }
}

.button {
  width: 100%;
  @include theme.breakpointDown('md') {
    margin: 20px 0 0 0;
  }
}
