@use 'src/styles/theme';

.root {
  @include theme.create-padding(2);
  background-color: theme.$d1;
  border-radius: 6px;
  color: theme.$white;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &__default {
    color: theme.$white;
  }

  &__search {
    color: theme.$d1;
  }
}
