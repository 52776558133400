.root {
  bottom: -4px;
  visibility: hidden;

  &,
  &:before {
    background: inherit;
    height: 8px;
    position: absolute;
    width: 8px;
  }

  &:before {
    content: '';
    transform: rotate(45deg);
    visibility: visible;
  }
}
