$spacing: 10px;

@function spacing($multiplier: 1) {
  @return $multiplier * $spacing;
}

@mixin create-padding(
  $top,
  $right: $top,
  $bottom: $top,
  $left: $right,
  $property: padding
) {
  #{$property}: ($spacing * $top) ($spacing * $right) ($spacing * $bottom)
    ($spacing * $left);
}

@mixin create-margin($top, $right: $top, $bottom: $top, $left: $right) {
  @include create-padding($top, $right, $bottom, $left, margin);
}
