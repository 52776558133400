@use 'src/styles/theme';

.contents {
  &__clickable {
    cursor: pointer;
  }
}

.playButton {
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @include theme.breakpointDown('md') {
    flex-shrink: 0;
    position: relative;
    opacity: 1;
    left: auto;
    top: auto;
    transform: none;
  }
}

.podcastLink {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: theme.$white;
  }
}

.podcast {
  font-weight: bold;
  text-decoration: none;
}

.button {
  width: 100%;
  @include theme.breakpointDown('md') {
    margin: 20px 0 0 0;
  }
}

.headerContents {
  width: 100%;
}
