@use 'src/styles/theme';
@use 'src/styles/utils';

.root {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.25) 6.4%,
    rgba(0, 0, 0, 0) 19.5%,
    rgba(0, 0, 0, 0) 76.5%,
    rgba(0, 0, 0, 0.5) 89.44%,
    rgba(0, 0, 0, 0.6)
  );
  border-radius: inherit;
  color: theme.$white;
  height: 100%;
  left: 0;
  padding: 18px 16px 16px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.thumbnail {
  width: 30px;
}

.title {
  @include utils.with-ellipsis(2);
}

.episode {
  @include utils.with-ellipsis(2);
}

.footer {
  width: 100%;
}

.continueListening {
  pointer-events: auto;
}
