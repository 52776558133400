@font-face {
  font-family: CourierPrime;
  font-style: normal;
  font-weight: normal;
  src: url(../assets/fonts/CourierPrime-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Montserrat-Bold.ttf) format('truetype');
}
