.toast-container {
  width: auto;
  padding: 0;
  border-radius: 10px;
}

.toast {
  padding: 0;
  margin: 0;
  min-height: unset;
  border-radius: 10px;
}

.body {
  padding: 0;
  border-radius: 10px;
}
