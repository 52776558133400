@use 'src/styles/theme';

.root {
  position: relative;

  &__loading {
    &::after {
      animation: rotate 1s linear infinite;
      content: '';
      border: 2px solid transparent;
      border-top-color: white;
      border-radius: 50%;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}
