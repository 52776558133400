@use 'src/styles/theme';

.icon {
  height: 16px;
  width: 16px;
  color: theme.$white;

  &:hover:not([disabled]) {
    opacity: 0.5;
    color: theme.$l4;
  }

  &:active:not([disabled]) {
    opacity: 0.8;
  }
  @include theme.breakpointDown('md') {
    height: 12px;
    width: 12px;
  }
}

.text {
  display: flex;
  color: theme.$white;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;
}
