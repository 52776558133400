@use 'src/styles/theme';

.root {
  @include theme.transition(color);
  color: theme.$action-primary-main;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    color: theme.$action-primary-dark;
  }
}
