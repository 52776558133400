@use 'src/styles/theme';

.header {
  z-index: 4;
  &__fade {
    &:before {
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0) 98%
      );
      content: '';
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
}

.overlay {
  height: theme.$homepage-header-height;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;

  &:before {
    background-image: linear-gradient(
      to bottom,
      rgba(theme.$dark3-main, 0.6),
      theme.$d2
    );
    content: '';
    height: theme.$homepage-header-height;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &__ending {
    &:before {
      background-image: linear-gradient(
        to top,
        rgba(theme.$dark3-main, 0.6),
        theme.$d2
      );
      content: '';
      height: theme.$homepage-header-height;
      top: theme.$homepage-footer-height;
      position: absolute;
      width: 100%;
      z-index: 2;
    }
  }
}

.banner {
  height: theme.$homepage-header-height;
  display: block;
  opacity: 1;
  top: 0;
  transition: all 0.3s linear;
  &__hidden {
    display: none;
    opacity: 0;
  }
  &__ending {
    top: theme.$homepage-footer-height;
  }
}

.contents {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
