@use 'src/styles/theme';

.root {
  height: 100%;

  @include theme.breakpointDown('md') {
    max-width: 310px;
  }
}

.container {
  height: 100%;
  position: relative;
}

.img {
  width: 150px;
  height: 150px;
}

.title {
  color: white;
  margin-bottom: 10px;
  font-size: 40px;
  text-align: center;
  line-height: 1.35;
}

.subtitle {
  color: white;
  text-align: center;
  max-width: 550px;
  margin-bottom: 24px;
}

.link {
  &,
  &:hover {
    text-decoration: none;
  }
}
