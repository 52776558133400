@use 'src/styles/theme';

.playbar {
  @include theme.create-padding(1.5, 3, 1.5, 2);
  backdrop-filter: blur(5px);
  background-color: rgba(theme.$d1, 0.75);
  width: 100%;
}

.segment {
  display: flex;
  flex: 1;
  justify-content: center;

  &:first-child > :first-child {
    margin-right: auto;
  }

  &:last-child > :first-child {
    margin-left: auto;
  }
}

.fader {
  background-image: linear-gradient(to top, theme.$d2, rgba(theme.$d2, 0%));
  bottom: -(theme.$slider-thickness);
  height: 50px;
  position: relative;
  width: 100%;
}

@include theme.breakpointDown('md') {
  .playbar {
    @include theme.create-padding(1);
  }

  .segment__time {
    display: none;
  }

  .segment__playback {
    flex: 0 1 auto;
  }
  .controls {
    z-index: 4;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 50px;
  opacity: 0;
  z-index: 3;
}
