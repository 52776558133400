@use 'src/styles/theme';

.root {
  display: flex;

  &__horizontal {
    flex-direction: row;
  }

  &__vertical {
    flex-direction: column;
  }

  &__align {
    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-center {
      align-items: center;
    }
  }

  &__justify {
    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-center {
      justify-content: center;
    }

    &-around {
      justify-content: space-around;
    }

    &-between {
      justify-content: space-between;
    }
  }
}
