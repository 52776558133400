@use 'sass:color';
@use 'src/styles/theme';

$active-hover-shadow: inset 0 3px 5px rgb(0 0 0 / 25%);

.root {
  @include theme.transition(background-color);

  align-items: center;
  border: none;
  // https://stackoverflow.com/a/29966500
  border-radius: 1000px;
  cursor: pointer;
  display: flex;
  font-family: theme.$primary-font;
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 2px;
  line-height: 1;
  position: relative;
  text-transform: uppercase;

  &__fluid {
    width: 100%;
  }

  &__large {
    @include theme.create-padding(0, 2.6);
    height: 50px;
  }

  &__small {
    @include theme.create-padding(1, 1.5);
    height: 30px;
  }

  &__primary {
    background-color: theme.$action-primary-main;
    color: theme.$white;

    &:hover {
      background-color: theme.$action-primary-dark;
    }
  }

  &__dark {
    background-color: theme.$dark3-main;

    &:hover {
      background-color: theme.$neutral1-dark;
    }
  }

  &__gradient {
    $gradient-color-1: #5dbefc;
    $gradient-color-2: #dd92fe;
    $gradient-angle: 108deg;

    &,
    &:hover {
      background-image: linear-gradient(
        $gradient-angle,
        $gradient-color-1,
        $gradient-color-2
      );
      color: theme.$white;
      z-index: 1;
    }

    &:before {
      background-image: linear-gradient(
        $gradient-angle,
        $gradient-color-2,
        $gradient-color-1
      );
      border-radius: inherit;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    &:hover:before {
      animation-name: animate-gradient;
      animation-duration: 1s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    &:active:before {
      box-shadow: $active-hover-shadow;
    }
  }

  &:active {
    box-shadow: $active-hover-shadow;
  }
}

@keyframes animate-gradient {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
