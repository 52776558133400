@use 'src/styles/theme';

.root {
  left: 0;
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 100%;
  z-index: 1;
}
