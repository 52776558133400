@use 'src/styles/theme';
@use 'src/styles/utils';

.root {
  color: theme.$white;
  padding-top: theme.spacing(3);
  width: 335px;
}

.thumbnail {
  flex-shrink: 0;
  width: 45px;
}

.podcastName {
  @include utils.with-ellipsis(2);
}

.episodeTitle {
  @include utils.with-ellipsis(4);
}

.episodeDescription {
  @include utils.with-ellipsis(5);
  max-width: 335px;
}

.buttonContainer {
  margin-top: theme.spacing(3);
}

.button {
  margin-top: theme.spacing(1);
}
