@use 'src/styles/theme';
@use 'src/styles/utils';

.footer {
  align-items: center;
  display: flex;
}

.date {
  @include theme.create-margin(0, 0, 0, 2);
  color: theme.$action-tertiary-main;

  &__search {
    color: theme.$black;
  }
}
