@use 'src/styles/theme';
@import 'src/styles/utils';

.root {
  letter-spacing: 1;
  @include with-ellipsis();
}

.link,
.link:visited {
  color: inherit;
}
